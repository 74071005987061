.Pagination {
  display: flex;
  justify-content: center;

  &-Link {
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 0 4px;

    text-align: center;
    vertical-align: middle;

    font-weight: 700;
    text-decoration: none;
    color: $black;

    border-radius: $border-radius * 100;
    background-color: $white;
    transition: all $animation-duration-quick ease-out;

    @media (min-width: 450px) {
      margin: 0 6px;
    }

    &:hover {
      background-color: $purple;
      color: $white;
    }

    &:not([href]) {
      color: $gray-b90;

      &:hover {
        color: $gray-b90;
        background-color: transparent;
      }
    }

    // Position matters. :not([href]) needs to be above.
    &_current,
    &_current:not([href]) {
      color: $white;
      background-color: $black;

      &:hover {
        background-color: $black;
      }
    }

    &_noBg {
      background-color: transparent;
    }

    // Hide all except nearest neighbor resulting in only 3 Links.
    &_target_prev:not(&_prevPosition_last),
    &_target_next:not(&_nextPosition_first) {
      display: none;

      @media (min-width: map-get($map: $grid-breakpoints, $key: 'md')) {
        display: initial;
      }
    }
  }

  &-Icon {
    width: 40px;
    height: 40px;

    &_first {
      @extend .Pagination-Icon;
    }
  }

  &-LinkPrev,
  &-LinkNext {
    margin: 0;

    @media (min-width: 450px) {
      margin: 0 6px;
    }
  }
}
