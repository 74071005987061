html,
body {
  margin: 0;
}

body {
  font-family: $serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: $black;
  background-color: $gray-b97;
}

main {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $sans-serif;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 8px;
  word-break: break-word;
  color: $black;
}

h1 {
  font-size: 52px;
  line-height: 56px;

  @media (min-width: 1900px) {
    font-size: 80px;
    line-height: 90px;
  }
}

h2 {
  font-size: 28px;
  line-height: 36px;
}

h3 {
  font-size: 20px;
  line-height: 26px;
}

h4 {
  font-size: 18px;
  line-height: 22px;
}

p {
  margin-bottom: 32px;
}

strong {
  font-weight: 700;
}

img {
  max-width: 100%;
}

a {
  color: $purple;
}
