.Cards {
  margin-bottom: -64px;
}

.Card {
  background-color: $white;
  border-radius: $border-radius;
  height: 100%;

  &-Col {
    margin-bottom: 64px;
  }

  &-ImageLink {
    display: block;
  }

  &-Image {
    display: block;
    width: 100%;
    border-radius: $border-radius $border-radius 0 0;
    transition: all $animation-duration-quick ease-out;

    &:hover {
      filter: brightness(1.1);
    }
  }

  &-Texts {
    padding: 32px 16px;

    @media (min-width: 450px) {
      padding: 32px;
    }
  }

  &-Date {
    margin: 0 0 16px;
    color: $gray-b47;
    font-size: 14px;
    line-height: 18px;
  }

  &-Title {
    margin: 0 0 16px;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
  }

  &-Description {
    margin: 16px 0 0;
  }

  &-TitleLink {
    color: $black;
    transition: all $animation-duration-quick ease-out;

    &:hover {
      color: $purple;
    }
  }
}
