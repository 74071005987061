.Switch {
  display: flex;
  align-items: center;

  &-Label {
    position: relative;
    display: inline-block;
    width: 64px;
    height: 32px;
  }

  &-Input {
    opacity: 0;
    width: 0;
    height: 0;

    &:focus + .Switch-Slider {
      outline: 1px solid $black;
    }
  }

  &-Slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gray-b90;
    border-radius: 64px;
    transition: all 250ms ease-out;

    @at-root .Switch-Input:checked + & {
      background-color: $green;
    }

    @at-root .Switch-Input:disabled + & {
      opacity: .32;
      cursor: not-allowed;
    }

    &:before {
      position: absolute;
      content: "";
      height: 24px;
      width: 24px;
      left: 4px;
      bottom: 4px;
      border-radius: 50%;
      background-color: $white;
      transition: all 250ms ease-out;

      @at-root .Switch-Input:checked + & {
        transform: translateX(32px);
      }
    }
  }

  &-StateText {
    font-family: $sans-serif;
    font-size: 15px;

    &_left {
      @extend .Switch-StateText;
      margin-right: 8px;
    }

    &_right {
      @extend .Switch-StateText;
      margin-left: 8px;
    }

    @at-root .Switch-Input:disabled + & {
      opacity: .32;
    }
  }
}
