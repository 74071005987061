.KidsEnrollment {
  padding: 64px 0;
  background-color: $black;

  @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
    padding: 128px 0;
  }

  &-ContentWrapper {
    background-color: $white;
    padding: 64px 0;

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'xl')) {
      padding: 128px 0;
    }
  }

  &-Row {
    position: relative;
  }

  &-Texts {
    @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
      padding-right: 16px;
    }
  }

  &-Title {
    margin: 0;
    font-size: 48px;
    line-height: 58px;
    text-transform: uppercase;

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
      font-size: 64px;
      line-height: 72px;
    }

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'md')) {
      font-size: 80px;
      line-height: 88px;
    }

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
      font-size: 64px;
      line-height: 72px;
    }

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'xl')) {
      font-size: 80px;
      line-height: 88px;
    }
  }

  &-Richtext {
    p {
      margin: 0;
    }
  }

  &-CTA {
    margin-top: 32px;
  }

  &-ImageWrapper {
    position: absolute;
    top: -64px;
    bottom: -64px;
    left: 50%;
    width: 50vw;
    max-width: none;

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'xl')) {
      top: -128px;
      bottom: -128px;
    }

    &.col {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-Image {
    &_vertical {
      width: 100%;
      padding-bottom: 100%;
      background-size: cover;
      background-position: center;

      @media (min-width: 450px) {
        padding-bottom: 75%;
      }

      @media (min-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
        padding-bottom: 70%;
      }

      @media (min-width: map-get($map: $grid-breakpoints, $key: 'md')) {
        padding-bottom: 60%;
        background-position: center -100px;
      }

      @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
        display: none;
      }

      @at-root html.webp &.lazy.applied {
        background-image: url('/images/nabor-deti/wimbledon-kurty-vertical.webp') !important;

        @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
          background-image: url('/images/nabor-deti/wimbledon-kurty-vertical-2x.webp') !important;
        }
      }

      @at-root html.no-webp &.lazy.applied {
        background-image: url('/images/nabor-deti/wimbledon-kurty-vertical.jpg') !important;

        @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
          background-image: url('/images/nabor-deti/wimbledon-kurty-vertical-2x.jpg') !important;
        }
      }
    }

    &_horizontal {
      display: none;
      background-size: cover;
      background-position: center;

      @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
        display: block;
        height: 100%;
      }

      @at-root html.webp &.lazy.applied {
        @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
          background-image: url('/images/nabor-deti/wimbledon-kurty-horizontal.webp') !important;

          @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
            background-image: url('/images/nabor-deti/wimbledon-kurty-horizontal-2x.webp') !important;
          }
        }
      }

      @at-root html.no-webp &.lazy.applied {
        @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
          background-image: url('/images/nabor-deti/wimbledon-kurty-horizontal.jpg') !important;

          @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
            background-image: url('/images/nabor-deti/wimbledon-kurty-horizontal-2x.jpg') !important;
          }
        }
      }
    }
  }
}
