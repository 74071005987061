.NavigationTopDesktop {
  display: none;

  @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
    display: block;
  }

  > .NavigationTop-Ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    > .NavigationTop-Li {
      position: relative;
      flex: 0 0 auto;
    }

    .NavigationTop-Ul {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      top: 0;
      left: 0;
      width: 1px;
      height: 1px;
      clip: rect(0 0 0 0);
      white-space: nowrap;

      background-color: $white;
      border-radius: $border-radius;
      box-shadow: 0 0 8px rgba(0, 0, 0, .16);
      transition: all $animation-duration-quick ease-out;
      list-style-type: none;
      margin: 0;
      padding: 16px;

      &_open {
        visibility: visible;
        opacity: 1;
        width: auto;
        height: auto;
        clip: unset;
        top: 48px;
      }

      & .NavigationTop-Link {
        color: $black;
        text-shadow: none;

        &.NavigationLink:hover:before {
          border-color: $black;
          // Make it smaller than padding 16px (it looks better like this).
          left: 8px;
          right: 8px;
        }
      }
    }
  }

  .NavigationTop-Link {
    display: inline-block;
    // 10px wide is the triangle.
    // 8px is padding from left of the text.
    // This makes it 16px from both sides.
    padding: 8px (16px + 10px + 8px) 8px 16px;
    font-size: 16px;
    font-weight: 300;
    color: $black;
    text-decoration: none;

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 12px;
      top: 15px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 10px solid $gray-b90;
    }

    &:only-child {
      padding-right: 16px;

      &:after {
        display: none;
      }
    }

    @at-root .Header_style_transparent & {
      color: $white;
      text-shadow: 0 0 4px rgba(0, 0, 0, .32);

      &.NavigationLink:before {
        border-color: $white;
      }
    }
  }
}
