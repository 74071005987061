.LatestBlogEntries {
  position: relative;
  margin-top: -128px;

  @media (min-width: 450px) {
    margin-top: -200px;
  }

  @media (min-width: 1600px) and (min-height: 900px) {
    margin-top: -401px;
  }
}

.LatestBlogEntry {
  position: relative;

  &:hover .LatestBlogEntry-Image {
    filter: brightness(1.1);
  }

  &_desktopThumbnail {
    display: none;

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      display: block;
    }

    &:hover .LatestBlogEntry-Image {
      filter: none;
    }

    & .LatestBlogEntry {
      margin-left: 0;

      &-Col {
        position: relative;
        display: flex;
        align-items: center;
        padding: 32px;
        background-color: $white;
        border-radius: $border-radius 0 0 $border-radius;

        &:after {
          content: '';
          position: absolute;
          z-index: 1;
          display: block;
          top: 0;
          bottom: 0;
          // to prevent spaces when user zooms
          right: -199px;
          width: 200px;
          background-image: url('/images/shapes/trapezoid.svg');
          background-repeat: no-repeat;
          background-position: top left;
          pointer-events: none;
        }
      }

      &-Texts {
        position: static;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-right: -32px;

        > * {
          flex: 0 1 auto;
        }
      }

      &-TitleLink,
      &-Excerpt {
        color: $black;
        text-shadow: none;
      }

      &-TitleLink {
        background-color: unset;

        &:hover {
          color: $purple;
          background-color: unset;
        }
      }

      &-ImageLink {
        padding-left: 0;

        &:after {
          left: 0;
          right: 0;
          background: rgba(0, 0, 0, .1);
          border-radius: 0 $border-radius $border-radius 0;
        }

        &:hover .LatestBlogEntry-Image {
          filter: brightness(1.1);
        }
      }

      &-Image {
        display: block;
        width: 100%;
        border-radius: 0 $border-radius $border-radius 0;
        transition: all $animation-duration-quick ease-out;
      }
    }
  }

  &-ColWrapper {
    margin-bottom: 32px;

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      margin-bottom: 64px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        display: none;
      }
    }
  }

  &-ImageLink {
    position: relative;
    display: block;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to bottom, rgba(0, 0, 0, .1), rgba(0, 0, 0, .1) 50%, rgba(0, 0, 0, .6) 75%, rgba(0, 0, 0, 1));
      border-radius: $border-radius $border-radius $border-radius 0;
    }

    &_rectangle {
      .LatestBlogEntry-Image {
        @media (min-width: map-get($grid-breakpoints, 'lg')) {
          display: none;
        }
      }

      .LatestBlogEntry-Image_rectangle {
        @media (min-width: map-get($grid-breakpoints, 'lg')) {
          display: block;
        }
      }

      &.LazyLoad-Wrapper_rectangle {
        @media (max-width: map-get($grid-breakpoints, 'lg') - 0.5px) {
          padding-top: 100%;
        }
      }
    }
  }

  &-Image {
    display: block;
    width: 100%;
    border-radius: $border-radius $border-radius $border-radius 0;
    transition: all $animation-duration-quick ease-out;

    &_rectangle {
      display: none;
    }
  }

  &-Texts {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 16px;

    @media (min-width: 450px) {
      left: 32px;
      right: 32px;
      bottom: 32px;
    }

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      left: 16px;
      right: 16px;
      bottom: 16px;
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      left: 32px;
      right: 32px;
      bottom: 16px;
    }
  }

  &-Title {
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;

    @media (min-width: 450px) and (max-width: map-get($grid-breakpoints, 'md') - 1px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &-TitleLink {
    color: $white;
    background-color: $black;
    text-decoration: underline;
    text-shadow: 0 0 4px rgba(0, 0, 0, 1);
    transition: all $animation-duration-quick ease-out;

    &:hover {
      background-color: $purple;
    }
  }

  &-Excerpt {
    display: none;
    color: $white;
    margin: 8px 0 0;
    font-size: 15px;
    line-height: 19px;
    text-shadow: 0 0 4px rgba(0, 0, 0, 1);

    @media (min-width: 450px) {
      display: block;
    }

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      display: none;
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      display: block;
    }
  }

  &-StackedRow {
    .LatestBlogEntry-ColWrapper:first-child {
      display: block;
      margin-bottom: 30px;
    }

    .LatestBlogEntry-Excerpt {
      max-height: 38px;
      overflow: hidden;
    }

    .LatestBlogEntry-ImageLink:after {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.6) 65%, black);
    }
  }
}

.Pill {
  display: inline-block;
  // @note to make tapable target at least 48px (meeting google standard)
  padding: 10px 0;

  &-Text {
    display: inline-block;
    padding: 4px 16px;
    background-color: $black;
    color: $white;
    border-radius: $border-radius;
    text-decoration: none;
    text-transform: uppercase;
    font-family: $sans-serif;
    font-size: 12px;
    line-height: 20px;
    transition: all $animation-duration-quick ease-out;

    @at-root .Pill:hover & {
     background-color: $purple;
    }
  }
}
