.Page404 {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 128px 0;

  @include responsive-background-image($selector: '.Page404', $folderUrl: '/images/error-page', $filename: '404', $extension: '.jpg', $breakpoints: (800, 1400, 1920));

  @media (min-width: map-get($map: $grid-breakpoints, $key: 'xl')) {
    padding: 256px 0;
  }

  &-DarkenOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .08);
  }

  &-Title {
    color: $white;
    text-shadow: 0 0 8px rgba(0, 0, 0, .32);
  }

  &-Content {
    color: $white;
    text-shadow: 0 0 8px rgba(0, 0, 0, .32);
  }
}
