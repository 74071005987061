.CookiesSettingsPage {
  &-Divider {
    height: 2px;
    margin-top: 32px;
    margin-bottom: 32px;
    background-color: $gray-b97;
  }

  &-Buttons {
    // @note to compensate &-SaveButton margin-bottom.
    margin-bottom: -16px;
  }

  &-SaveButton {
    margin-right: 16px;
    margin-bottom: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}
