.Entry {
  &-Bg {
    background-color: $white;
  }

  &-Content {
    margin-bottom: 64px;
  }

  &-FeaturedImageWrapper {
    width: 100vw;
    padding-top: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-bottom: 32px;

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
      width: 100%;
      padding-top: 100%;
      margin-left: 0;
    }
  }

  &-FeaturedImage {
    display: block;
    width: 100vw;

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
      width: 100%;
    }
  }

  &-FootNote {
    margin: 64px 0 0;
    color: $gray-b47;

    a {
      color: inherit;
    }
  }
}
