.Hero {
  position: relative;
  padding-bottom: 290px;
  background-size: cover;
  background-position-y: 80%;
  background-repeat: no-repeat;

  @media (min-width: 450px) {
    padding-bottom: 418px;
  }

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    padding-bottom: 460px;
  }

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    padding-bottom: 510px;
  }

  @media (min-width: 1600px) and (min-height: 900px) {
    height: 100vh;
    padding-bottom: 0;
  }

  @at-root html.webp & {
    background-image: url('/images/hero/phone-1x.webp');

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      background-image: url('/images/hero/desktop-1x.webp');
    }
  }

  @at-root html.no-webp & {
    background-image: url('/images/hero/phone-1x.jpg');

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      background-image: url('/images/hero/desktop-1x.jpg');
    }
  }

  @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
    @at-root html.webp & {
      background-image: url('/images/hero/phone-2x.webp');

      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        background-image: url('/images/hero/desktop-2x.webp');
      }
    }

    @at-root html.no-webp & {
      background-image: url('/images/hero/phone-2x.jpg');

      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        background-image: url('/images/hero/desktop-2x.jpg');
      }
    }
  }

  &-TextsContainer {
    position: relative;
    // Should be 32px from header.
    top: 90px;
    padding-top: 30px;
    padding-bottom: 32px;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      // Should be 64px from header.
      top: 90px + 32px;
    }

    @media (min-width: 1900px) {
      top: 90px + 64px;
      padding: 46px 0 48px;
      border-radius: 0 $border-radius * 100 $border-radius * 100 0;
    }
  }

  &-TextsBg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: calc(100vw - 24px);
    background: linear-gradient(to right, $green-darker, #4F4383 65%, $purple);
    border-radius: 0 $border-radius $border-radius 0;

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      border-radius: 0 $border-radius * 100 $border-radius * 100 0;
    }
  }

  &-Texts {
    padding-right: 16px + 24px;
    // Moves BG to the left so it is aligned with the right side of container.
    // 32 is because there are 2 cols in a row.

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      flex: 0 0 auto;
      width: auto;
      padding-right: 16px;
      max-width: 335px;
    }

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      max-width: 482px;
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      padding-right: 128px;
      max-width: none;
    }
  }

  &-TextsLogo {
    display: none;

    @media (min-width: 450px) {
      display: block;
    }
  }

  &-Logo {
    display: block;
    width: 128px;
    height: 128px;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      width: 160px;
      height: 160px;
    }

    @media (min-width: 1900px) {
      width: 200px;
      height: 200px;
      margin-right: 32px;
    }
  }

  &-Title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 40px;
    line-height: 48px;
    color: $white;

    @media (min-width: 355px) {
      font-size: 46px;
      line-height: 52px;
    }

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      font-size: 62px;
      line-height: 62px;
    }

    @media (min-width: 1900px) {
      font-size: 92px;
      line-height: 92px;
    }
  }

  &-Subtitle p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 0;
    color: $white;
  }

  &-Curves {
    position: absolute;
    // to prevent spaces when user zooms
    bottom: -1px;
    width: 100%;
  }
}
