.Footer {
  padding: 64px 0 32px;
  background: linear-gradient(to right bottom, #78CB94, #887B98);
  color: $black;

  @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
    padding-top: 128px;
  }
}

.FootNote {
  font-size: 12px;
  line-height: 18px;

  p {
    margin: 8px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
