.ContentMatrix {
  // Every element has margin-bottom of 32px
  margin-bottom: -32px;

  &-Richtext {
    overflow: hidden;

    a {
      color: $purple;
      transition: all $animation-duration-quick ease-out;

      &:hover {
        color: $purple-darker;
      }
    }

    p {
      margin-top: 8px;
    }

    ul {
      margin: 32px 0;
      padding-left: 32px;
    }

    h2, h3 {
      & + p {
        margin-top: 8px;
      }
    }

    h2, h3 {
      & + ul,
      & + table {
        margin-top: 16px;
      }
    }

    figure {
      margin: 16px 0 32px;

      iframe {
        display: block;
        max-width: 100%;
        overflow: hidden;
        padding: 0;
      }
    }

    &TableWrapper {
      overflow-x: scroll;
    }

    table {
      border-collapse: collapse;

      thead {
        font-weight: 700;
      }

      th,
      td {
        padding: 16px 32px;
        border-right: 2px solid $gray-b90;

        &:last-child {
          border-right: none;
        }
      }

      thead tr,
      tr:not(:last-child) {
        border-bottom: 2px solid $gray-b90;
        }
      }
    }

  &-ImageWrapper {
    margin-bottom: 32px;
  }

  &-Image {
    display: block;
    width: 100%;
    max-width: 100%;
  }
}
