.LazyLoad {
  &-Wrapper {
    position: relative;
    width: 100%;
    height: 0;

    &_square {
      @extend .LazyLoad-Wrapper;
      padding-top: 100%;
    }

    &_rectangle {
      @extend .LazyLoad-Wrapper;
      padding-top: ((247.4 * 100) / 526) * 1%;
    }
  }

  &-Picture {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &-Image.loading {
    width: 100%;
    height: 100%;
    background-color: $gray-b97;

    // https://codepen.io/camdenfoucht/pen/BVxawq
    /* TRIPLE SPINNER */
    + .LazyLoad-Spinner {
      display: block;
      position: absolute;
      $size: 70px;
      top: calc(50% - #{$size / 2});
      left: calc(50% - #{$size / 2});
      width: $size;
      height: $size;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top: 3px solid $purple;
      animation: LazyLoad-Spin 1s linear infinite;

      &::before,
      &::after {
        content: "";
        position: absolute;
        border-radius: 50%;
        border: 3px solid transparent;
      }

      &::before {
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
        border-top-color: $green;
        animation: LazyLoad-Spin 2s linear infinite;
      }

      &::after {
        top: 12px;
        left: 12px;
        right: 12px;
        bottom: 12px;
        border-top-color: #78CB94;
        animation: LazyLoad-Spin 1s linear infinite;
      }
    }
  }
}

@keyframes LazyLoad-Spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
