.Partners {
  padding-top: 64px;
  // Bottom is only 32 as every image has 32px margin-bottom.
  // Final padding should be 64px like this.
  padding-bottom: 64px - 32px;

  @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
    padding-top: 128px;
    padding-bottom: 128px - 32px;
  }

  &-Title {
    margin: 0 0 32px;
    font-family: $sans-serif;
    font-size: 28px;
    line-height: 34px;

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'md')) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &-Link {
    display: block;
    margin-bottom: 32px;
  }

  &-Image {
    display: block;
    width: auto;
    height: auto;
    max-width: 130px;
    max-height: 60px;
    filter: grayscale(1);
    transition: all $animation-duration-quick ease-out;

    &:hover {
      filter: grayscale(0);
    }

    &:not(.loaded) {
      min-width: 60px;
      min-height: 60px;
    }
  }
}
