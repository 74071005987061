@mixin backgroundIcon($url, $width, $height) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: $width $height;
  width: $width;
  height: $height;
}

@mixin responsive-background-image($selector, $folderUrl, $filename, $extension, $breakpoints) {
  @at-root html.no-webp #{$selector} {
    @include responsive-background-image-for-extension($folderUrl, $filename, $extension, $breakpoints);
  }

  @at-root html.webp #{$selector} {
    @include responsive-background-image-for-extension($folderUrl, $filename, $extension: '.webp', $breakpoints: $breakpoints);
  }
}

@mixin responsive-background-image-for-extension($folderUrl, $filename, $extension, $breakpoints) {
  background-image: url('#{$folderUrl}/#{$filename}#{$extension}');

  @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
    background-image: url('#{$folderUrl}/#{$filename}-x2#{$extension}');
  }

  @each $breakpoint in $breakpoints {
    @media (min-width: #{$breakpoint}px) {
      background-image: url('#{$folderUrl}/#{$filename}-#{$breakpoint}#{$extension}');

      @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
        background-image: url('#{$folderUrl}/#{$filename}-#{$breakpoint}-x2#{$extension}');
      }
    }
  }
}
