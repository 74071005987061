.Header {
  position: fixed;
  z-index: map-get($z-index, 'header');
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: $header-height;
  background-color: $white;
  transition: background-color 250ms ease-out;

  @at-root body {
    padding-top: $header-height;

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
      padding-top: $header-heightDesktopLg;
    }

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'xl')) {
      padding-top: $header-heightDesktopXl;
    }

    &.homepage {
      padding-top: 0;
    }
  }

  @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
    display: flex;
    align-items: center;
    height: $header-heightDesktopLg;
  }

  @media (min-width: map-get($map: $grid-breakpoints, $key: 'xl')) {
    display: flex;
    align-items: center;
    height: $header-heightDesktopXl;
  }

  &-Item {
    display: block;
  }

  &-Logo {
    text-align: center;
  }

  &-LogoLink {
    display: inline-block;
    padding: 8px;
    vertical-align: middle;

    &:hover * {
      animation: bounce 700ms;
      transform-origin: center bottom;
    }
  }

  &-LogoImage {
    display: block;
    width: 40px;
    height: 40px;

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
      width: 60px;
      height: 60px;
    }
  }

  &-CTA {
    text-align: right;
  }

  &-CTALink {
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 8px;
    font-family: $sans-serif;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    color: $purple;
    text-decoration: underline;
    transition: color $animation-duration-quick ease-out;

    @media (min-width: 325px) {
      font-size: 14px;
    }

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
      display: none;
    }
  }

  &-CTAButton.Button {
    display: none;

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
      display: inline-block;
    }

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) and (max-width: map-get($map: $grid-breakpoints, $key: 'xl')) {
      font-size: 15px;
      padding: 15px 24px 14px;
    }

    @at-root .Header_style_transparent .Header-CTAButton.Button {
      color: $white;
      background-color: transparent;
      border-width: 2px;
      padding: 15px 30px 14px;
      border-style: solid;
      background-color: transparent;

      @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) and (max-width: map-get($map: $grid-breakpoints, $key: 'xl')) {
        padding: 13px 22px 12px;
      }
    }
  }

  &_style_transparent {
    background-color: transparent;

    .Hamburger-Item {
      background-color: $white;
    }

    .Header-CTALink {
      color: $white;
    }

    .Header-CTAButton {
      color: $white;
      background-color: $purple;
      border: 0;

      &:hover {
        background-color: $purple;
        border-color: $purple;
      }
    }
  }

  &-NavigationTopDesktop {
    display: none;

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
      display: block;
    }
  }
}

.Hamburger {
  display: inline-block;
  padding-top: 8px;
  padding-bottom: 8px;
  transition: background-color 250ms ease-out;

  @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
    display: none;
  }

  &-Item {
    width: 32px;
    height: 3px;
    margin-bottom: 6px;
    background-color: $black;
    border-radius: $border-radius;

    &_position_2 {
      width: 22px;
    }

    &_position_3 {
      width: 13px;
      margin-bottom: 0;
    }
  }
}
