.CookiesConsent {
  position: fixed;
  z-index: map-get($map: $z-index, $key: 'cookiesConsent');
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 0;
  color: $white;
  background-color: $black;

  &-Title {
    margin-top: 0;
    margin-bottom: 8px;
    font-family: $sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
  }

  &-Description {
    font-size: 14px;
    line-height: 21px;
  }

  &-Link {
    color: $white;
  }

  &-Button {
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
}
