// Colors
$white: #ffffff;
$gray-b97: #F7F7F7;
$gray-b90: #E6E6E6;
$gray-b47: #787878;
$green: #1CB14D;
$green-darker: #12A342;
$purple: #5C2E91;
$purple-darker: darken($color: $purple, $amount: 10%);
$black: #141414;

// Fonts
$serif: 'Merriweather', serif;
$sans-serif: 'Roboto Condensed', sans-serif;

// z-index
$z-index: (
  header: 1000,
  navigationTopMobileOverlay: 1010,
  navigationTopMobile: 1015,
  navigationTopMobileUl: 1020,
  navigationTopMobileClose: 1025,
  cookiesConsent: 1030
);

// Animations
$animation-duration-quick: 150ms;

// Other
$border-radius: 16px;

// Layout
$header-height: 58px;
$header-heightDesktopLg: 84px;
$header-heightDesktopXl: 100px;
