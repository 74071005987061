.NavigationFooter {
  margin-bottom: 64px;

  @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
    margin-bottom: 128px;
  }

  &-Ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &-Link {
    display: inline-block;
    padding: 8px 0;
    color: $black;
    text-decoration: none;
  }

  & > .container > .NavigationFooter-Ul {
    @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
      display: flex;
      justify-content: space-between;
      margin-left: -16px;
      margin-right: -16px;
    }
    // First nested UL
    & > .NavigationFooter-Li {
      margin-bottom: 32px;

      @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
        flex: 0 0 33.33%;
        padding-right: 16px;
        padding-left: 16px;
        margin-bottom: 0;
      }

      & > .NavigationFooter-Link {
        padding: 0;
        margin-bottom: 8px;
        font-family: $sans-serif;
        font-size: 32px;
        line-height: 38px;

        &:hover:before {
          display: none;
        }
      }
    }
  }
}
