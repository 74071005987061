.Button {
  display: inline-block;
  // 17px at the top due to visual alignment
  padding: 17px 32px 16px;
  font-family: $sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;
  color: $black;
  background-color: $white;
  text-decoration: none;
  border-radius: 64px;
  border-color: $black;
  border-width: 0;
  cursor: pointer;
  transition: all $animation-duration-quick ease-out;
  transition-property: color, background-color;

  &:hover,
  &:focus {
    color: $white;
    background-color: $black;
    outline: none;
  }

  &_primary {
    color: $white;
    background-color: $purple;

    &:hover,
    &:focus {
      background-color: $purple-darker;
    }
  }

  // Needs to be last as it set transparent color and needs to override styles decalerd before.
  &_outline {
    border-width: 2px;
    // Padding is less by 2px than normal.
    padding: 15px 30px 14px;
    border-style: solid;
    background-color: transparent;
  }

  &_small {
    font-size: 14px;
    line-height: 19px;
    // 17px at the top due to visual alignment
    padding: 8px 16px;
  }
}
