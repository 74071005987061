.NavigationTopMobile {
  position: fixed;
  z-index: map-get($z-index, 'navigationTopMobile');
  visibility: hidden;
  opacity: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  top: 0;
  bottom: 0;
  left: 0;
  right: 100%;
  transition: all $animation-duration-quick ease-out;

  &_open {
    visibility: visible;
    opacity: 1;
    width: auto;
    height: auto;
    clip: unset;
    right: 0;
  }

  &-Overlay {
    visibility: hidden;
    opacity: 0;
    transition: all 250ms ease-out;

    @at-root .NavigationTopMobile_open & {
      position: fixed;
      z-index: map-get($z-index, 'navigationTopMobileOverlay');
      visibility: visible;
      opacity: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, .64);
    }
  }

  &-Wrapper {
    position: fixed;
    z-index: map-get($z-index, 'navigationTopMobileUl');

    top: 0;
    bottom: 0;
    left: 0;
    right: 100%;

    padding: 6px 0 64px;
    overflow: scroll;
    white-space: nowrap;

    background-color: $white;
    transition: all $animation-duration-quick ease-out;

    @at-root .NavigationTopMobile_open .NavigationTopMobile-Wrapper {
      right: 20%;
      padding-left: 24px;
      padding-right: 24px;

      @media (min-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
        right: 45%;
      }

      @media (min-width: map-get($map: $grid-breakpoints, $key: 'md')) {
        right: 55%;
      }
    }

    @at-root .NavigationTopMobile-Wrapper > .NavigationTop-Ul {
      margin: 0;
      padding: 0;
    }
  }


  .NavigationTop-Ul {
    list-style-type: none;

    & .NavigationTop-Ul {
      position: relative;
      height: 0;
      opacity: 0;
      overflow: hidden;
      margin-top: 8px;
      padding-left: 18px;
      transition: all 400ms ease-out;

      &:before {
        content: '';
        position: absolute;
        width: 2px;
        top: 0;
        left: 0;
        bottom: 100%;
        border-radius: $border-radius;
        background-color: $green;
        transition: all 300ms ease-out;
      }

      &_open {
        display: block;
        height: auto;
        opacity: 1;

        &:before {
          bottom: 0;
        }
      }
    }

  }

  .NavigationTop-Li {
    transition: all $animation-duration-quick ease;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &_dimmed {
      opacity: .64;

      .NavigationTop-Ul:before {
        background-color: $gray-b97;
      }
    }
  }

  .NavigationTop-Link {
    position: relative;
    display: inline-block;
    padding: 8px 0;
    font-size: 16px;
    font-weight: 300;
    color: $black;
    text-decoration: none;

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: -21px;
      top: 15px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 10px solid $gray-b90;
    }

    &:only-child:after {
      display: none;
    }
  }

  &-Close {
    position: relative;
    margin-left: -12px;
    margin-bottom: 16px;
  }

  &-CTA {
    margin-top: 32px;
    transform: scale(.9);
    transform-origin: left;
  }
}

.Close {
  width: 48px;
  height: 48px;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 28.5px;
    // Center it inside div
    top: 8px;
    left: 24px;
    background-color: $black;
    transform-origin: center;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}
