.About {
  &-Content {
    padding-top: 64px;
    padding-bottom: 64px;
    background-size: cover;
    background-position: center center;

    @at-root html.webp &.lazy.applied {
      background: url('/images/about/tennis-fun-koláž.webp'), $black !important;

      @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
        background: url('/images/about/tennis-fun-koláž-2x.webp'), $black !important;
      }
    }

    @at-root html.no-webp &.lazy.applied {
      background: url('/images/about/tennis-fun-koláž.jpg'), $black !important;

      @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
        background: url('/images/about/tennis-fun-koláž-2x.jpg'), $black !important;
      }
    }

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
      padding-top: 128px;
      padding-bottom: 128px;
    }
  }

  &-Card {
    max-width: 360px;
    margin: 0 auto 64px;
    background-color: $white;
    padding: 64px 32px;
    border-radius: $border-radius $border-radius $border-radius 0;
  }

  &-CardIcon {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;

    &_calendar-checkmark {
      @extend .About-CardIcon;
      @include backgroundIcon('/images/icons/calendar-checkmark.svg', 56px, 64px);
    }

    &_users {
      @extend .About-CardIcon;
      @include backgroundIcon('/images/icons/users.svg', 91.5px, 64px);
    }

    &_graduate {
      @extend .About-CardIcon;
      @include backgroundIcon('/images/icons/graduate.svg', 56px, 64px);
    }
  }

  &-CardTitle {
    text-align: center;
    font-family: $sans-serif;
    font-size: 24px;
    line-height: 25px;
    text-transform: uppercase;
    margin: 0 0 16px;
  }

  &-CardText {
    margin: 0;
  }

  &-CTAWrapper {
    text-align: center;
  }
}
