.Title {
  &_style_underlined {
    position: relative;
    display: inline-block;
      // 32px margin-bottom
    margin-bottom: 40px;

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
      margin-bottom: 48px;
    }

    @media (min-width: 1900px) {
      // 64px margin-bottom
      margin-bottom: 80px;
    }
  }

  &-Underline {
    position: absolute;
    display: block;
    width: calc(100% - 64px);
    height: 4px;
    bottom: -8px;
    left: 0;
    border-radius: 0 $border-radius * 10 $border-radius * 10 0;
    background: linear-gradient(to right, $green, $purple);

    @media (min-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
      height: 8px;
      bottom: -16px;
    }
  }
}
