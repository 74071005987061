.Sidebar {
  margin-bottom: 64px;

  &-Section:first-of-type .Sidebar-Title {
    @media (max-width: map-get($grid-breakpoints, 'lg') - 0.5px) {
      margin-top: 0;
    }
  }

  &-Title {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 22px;
  }

  &-NavUl {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &-NavLink {
    display: inline-block;
    padding: 4px 8px 4px 0;
    font-size: 15px;
    color: $gray-b47;
    text-decoration: underline;
    transition: all $animation-duration-quick ease-out;

    &:hover {
      color: $purple;
    }

    &:first-child {
      padding-top: 0;
    }
  }
}
