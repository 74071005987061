.NavigationLink {
  position: relative;
  display: inline-block;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    border-bottom: 2px solid $black;
    transition: all 100ms ease-out;
  }

  &:hover:before {
    left: 0;
    right: 0;
  }
}
