.ThanksMessage {
  padding: 32px 0;
  background-color: white;
  background-image: url('/images/icons/thank-you.svg');
  background-repeat: repeat;
  background-size: 283.5px 204.5px;
  background-position: 8px 20px;

  &-Title {
    margin: 0 0 32px;
    text-transform: uppercase;
  }

  &-Message {
    position: relative;

    &::before {
      content: '“';
      position: absolute;
      top: 3px;
      left: -18px;
      font-size: 32px;
      color: $gray-b90;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      left: -24px;
      top: -2px;
      width: 4px;
      height: 64px;
      background-color: $green;
    }
  }
}
