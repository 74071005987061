// Part of Bootstrap reboot
// This is required for grid to work properly
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit; // 1
}
